@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('//fonts.googleapis.com/css?family=Open+Sans|Open+Sans|Open+Sans');

body {
  @apply text-gray-900;
}

#__next {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

li a {
  @apply hover:underline;
}

:root {
  --color-gradient-1: #7d7aff;
  --color-gradient-2: #2121e2;
  --color-gradient-3: #00fff0;
  --color-gradient-4: #8785ff;
}

.text-primary-gradient {
  @apply text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-violet-500;
}

.link-primary {
  @apply text-secondary-800 hover:underline text-sm font-semibold cursor-pointer;
}
